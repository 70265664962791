<template>
  <div class="flex-1 overflow-x-auto overflow-y-auto">
    
    <PLoader v-if="isLoading" />

    <PTable 
      v-else
      @select="onSelectUser"
      :cols="cols"
      :data="filteredUsers"
    />

    <portal to="filters">
      <PToggle
        class="ml-4"
        color="primary"
        v-model="showInactive"
        :label="$tk('UserList.ShowInactive')"
      >
      </PToggle>
    </portal>
    <portal to="actions">
      <PButton
        @click="onCopyEmails"
        color="secondary"
        >
          <span v-html="$tk('UserList.CopyEmailAddresses')"></span>          
        </PButton>
      <PButton 
        class="ml-4" 
        @click="createOrUpdateUserDialogVisible = true" 
        color="primary"        
      >
        <span v-html="$tk('UserList.NewUser')"></span>
      </PButton>
    </portal>

    <UserDialog
      v-if="createOrUpdateUserDialogVisible"
      :customerId="customer.id"
      :userId="selectedUserId"
      @updated="loadUsers"
      @close="onCloseCreateOrUpdateUserDialog"
    />

  </div>
</template>

<script>

import api from "../api"
import { get, filter, map } from "lodash"
import { mapGetters } from "vuex"
import UserDialog from "./dialogs/UserDialog"
import { copyToClipboard } from '../../../helpers'

export default {
  
  name: "UserList",

  components: {
    UserDialog
  },

  data () {
    return {
      cols: [
        { text: this.$tk("Common.General.Id"), type:"id", width: "100", value: "loginName", sortable: true, },
        { text: this.$tk("Common.General.Name"), width: "250", value: "name",sortable: true },
        { text: this.$tk("Common.General.Location"), width: "350", value: "locationName", sortable: true },
        { text: this.$tk("Common.General.Email"), width: "350", value: "email", sortable: true },
        { text: this.$tk("Common.General.Phone"), width: "120", value: "phone", sortable: false },
        { text: this.$tk("UserList.LastLogin"), width:"250", value: "lastLogonAx", type: "datetime", sortable: true },
        { text: this.$tk('CreateOrUpdateUserDialog.Administrator'), width: "170", value: "isCustomerAdmin", type: "check", sortable: true, align: "center" },
        { text: this.$tk('CreateOrUpdateUserDialog.FinanceReports'), width: "140", value: "allowFinancialReports", type: "check", sortable: true, align: "center" },
        { text: this.$tk('CreateOrUpdateUserDialog.SupportUser'), width: "170", value: "isSuperUser", type: "check", sortable: true, align: "center" },
        { text: this.$tk('CreateOrUpdateUserDialog.SupportUserLocation'), width: "170", value: "isSuperUserLocation", type: "check", sortable: true, align: "center" }
        
        
      ],
      users: [],
      selectedUser: null,
      isLoading: true,
      showInactive: false,
      createOrUpdateUserDialogVisible: false
    }
  },
  
  computed: {

    ...mapGetters(["customer"]),

    selectedUserId () {
      return get(this.selectedUser, "id", "")
    },
    filteredUsers () {
      if (this.showInactive) {
        return filter(this.users, r => r.userStatusId === 0)
      } else {
        return filter(this.users, r => r.userStatusId === 1)
      }
    }
  },

  methods: {

    onSelectUser (user) {
      this.selectedUser = user
      this.createOrUpdateUserDialogVisible = true
    },

    onCloseCreateOrUpdateUserDialog () {
      this.createOrUpdateUserDialogVisible = false
      this.selectedUser = null
    },

    onCopyEmails () {      
      copyToClipboard(map(this.filteredUsers, u => u.email).join(";"))
      this.$notifySuccess(
        this.$t("UserList.CopyEmailAddresses"), 
        this.$t("UserList.EmailAddressesCopiedToClipboard")
      )
    },

    async loadUsers () {

      this.isLoading = true

      try {

        this.users = await api.usersGet(this.customer.id)
      
      } catch (error) {

        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason
        })

      }

      this.isLoading = false

    }
  },

  async created () {    
      await this.loadUsers()
  }

}
</script>


