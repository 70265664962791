<template>
  <portal to="dialogs">
    <PDialog
      :title="dialogTitle"
      :loading="isLoading"
      @close="$emit('close')"
      visible="visible"
      classes="left-0 md:left-auto md:w-3/5 lg:w-2/5"
    >

      <form
        class="flex flex-col flex-1 overflow-y-hidden"
        @submit.prevent="onSubmitPassword"
        v-if="changePasswordVisible"
      >
        <PDialogContent>
          <div class="absolute inset-0 flex items-center justify-center flex-1 bg-black bg-opacity-50 rounded-md">
            <div class="p-4 bg-white shadow-md rounded-md">
              <h2 class="font-semibold">
                <PTranslation k="CreateOrUpdateUserDialog.NewPassword" />
              </h2>
              <PInput
                label="Nytt passord"
                :focus="true"
                :disabled="isSaving"
                :required="true"
                class="w-full mt-4"
                v-model="newPassword"
                type="password"
              />
              <PInput
                label="Bekreft nytt passord"
                :disabled="isSaving"
                :required="true"
                class="w-full mt-4"
                v-model="confirmNewPassword"
                type="password"
              />

              <PButtonGroup class="justify-center mt-8">
                <PButton
                  color="secondary"
                  :disabled="isSaving"
                  @click="onCancelChangePassword"
                >
                  <PTranslation k="Common.Actions.Cancel" />
                </PButton>
                <PButton
                  type="submit"
                  color="primary"
                  :disabled="isSaving || newPassword === '' || newPassword.length < 6 || (newPassword !== confirmNewPassword)"
                >
                  <PTranslation k="Common.Actions.Save" />
                </PButton>
              </PButtonGroup>
            </div>
          </div>
        </PDialogContent>
      </form>

      <form
        class="flex flex-col flex-1 overflow-y-hidden"
        @submit.prevent="onSubmit"
        v-else
      >
        <PDialogContent>

          <div class="mb-10">
            <div class="flex flex-wrap sm:pt-4">
              <PInput
                :label="$tk('admin.name')"
                :disabled="isSaving"
                :required="true"
                class="w-1/2"
                v-model="user.name"
                type="text"
              />

              <PInput
                :label="$tk('admin.phone')"
                :disabled="isSaving"
                :required="true"
                class="w-1/2 pl-2"
                v-model="user.phone"
                type="text"
              />

              <PInput
                :label="$tk('admin.email')"
                :disabled="isSaving"
                :required="true"
                class="w-full mt-4"
                v-model="user.email"
                type="text"
              />

              <PSelect
                class="w-full mt-4"
                v-slot="{ item }"
                v-model="user.locationId"
                :label="$tk('admin.location')"
                :disabled="isSaving"
                :items="locations"
                :searchable="true"
                :required="true"
                itemText="name"
                itemValue="id"
                @input="updateWebTaskVisibility()"
              >
                {{ item.name }} ({{ item.id }})
              </PSelect>

              <PUserLocations
                class="w-full mt-4"
                :userLocationId="user.locationId"
                :locations="locations"
                :disabled="isSaving"
                v-model="userLocationIds"
              />

              <PCheckbox
                :label="$tk('CreateOrUpdateUserDialog.ActiveUser')"
                :disabled="isSaving"
                class="w-full mt-4"
                v-if="userId"
                v-model="userActive"
              />
            </div>

            <div class="flex flex-wrap mt-6 sm:pt-0">
              <h2
                class="w-full text-sm font-semibold text-gray-700"
                v-html="$tk('CreateOrUpdateUserDialog.Roles')"
              ></h2>

              <PCheckbox
                class="w-1/2 mt-2 md:w-1/3"
                v-model="user.isCustomerAdmin"
                :disabled="isSaving"
                :label="$tk('CreateOrUpdateUserDialog.Administrator')"
              />

              <PCheckbox
                class="w-1/2 mt-2 md:w-1/3"
                v-model="user.isSuperUser"
                :disabled="isSaving"
                :label="$tk('CreateOrUpdateUserDialog.SupportUser')"
              />

              <PCheckbox
                class="w-1/2 mt-2 md:w-1/3"
                v-model="user.isSuperUserLocation"
                :disabled="isSaving"
                :label="$tk('CreateOrUpdateUserDialog.SupportUserLocation')"
              />
            </div>

            <div class="flex flex-wrap mt-6 sm:pt-0">
              <h2
                class="w-full text-sm font-semibold text-gray-700 leading-5"
                v-html="$tk('CreateOrUpdateUserDialog.MenuAccess')"
              ></h2>

              <PCheckbox
                class="w-1/2 mt-2 md:w-1/3"
                v-show="webTask.visible"
                v-model="webTask.checked"
                v-for="(webTask, index) in webTasks"
                :disabled="isSaving"
                :key="index"
                :label="$tk(`Common.Webtasks.${webTask.id}`)"
              />
            </div>

            <div
              class="flex flex-wrap mt-6 sm:pt-0"
              v-if="customer.isPooling"
            >
              <h2 class="w-full text-sm font-semibold text-gray-700 leading-5">
                {{ $tk("CreateOrUpdateUserDialog.EmailNotifications") }}
              </h2>

              <PCheckbox
                class="w-1/2 mt-2"
                v-model="user.mailNewCust"
                :disabled="isSaving"
                :label="$tk('CreateOrUpdateUserDialog.MailNewCust')"
              />

              <PCheckbox
                class="w-1/2 mt-2"
                v-model="user.mailDisagree"
                :disabled="isSaving"
                :label="$tk('CreateOrUpdateUserDialog.MailDisagree')"
              />

              <PCheckbox
                class="w-1/2 mt-2"
                v-model="user.mailDeviation"
                :disabled="isSaving"
                :label="$tk('CreateOrUpdateUserDialog.MailDeviation')"
              />

              <PCheckbox
                class="w-1/2 mt-2"
                v-model="user.mailStock"
                :disabled="isSaving"
                :label="$tk('CreateOrUpdateUserDialog.MailStock')"
              />

              <PCheckbox
                class="w-1/2 mt-2"
                v-model="user.mailAutoCommitTO"
                :disabled="isSaving"
                :label="$tk('CreateOrUpdateUserDialog.MailAutoCommit')"
              />

              <PCheckbox
                class="w-1/2 mt-2"
                v-model="user.mailHighVolume"
                :disabled="isSaving"
                :label="$tk('CreateOrUpdateUserDialog.MailHighVolume')"
              />
            </div>

            <div class="flex flex-wrap mt-4 sm:pt-0">
              <PSelect
                class="w-full mt-2"
                itemText="label"
                itemValue="value"
                v-model="user.reportLevel"
                :label="$tk('CreateOrUpdateUserDialog.ReportLevel')"
                :disabled="isSaving"
                :required="true"
                :items="reports"
              />
              <div class="mt-1">
                <PCheckbox
                  v-model="user.allowFinancialReports"
                  :disabled="disableFinancialReportsCheckbox"
                  :label="$tk('CreateOrUpdateUserDialog.HasFinancialReports')"
                />
              </div>
            </div>

            <div class="flex flex-wrap mt-4 sm:pt-0">
              <PSelect
                class="w-full mt-4"
                v-model="user.language"
                :label="$tk('CreateOrUpdateUserDialog.Language')"
                :items="languages"
                :disabled="isSaving"
                :required="true"
                itemText="displayName"
                itemValue="name"
              />
            </div>

            <div class="flex flex-wrap mt-4 sm:pt-0">
              <h2
                class="w-full text-sm font-semibold text-gray-700 leading-5"
                v-html="$tk('CreateOrUpdateUserDialog.OtherPremissions')"
              ></h2>
              <PCheckbox
                class="mt-2"
                v-model="user.isDefaultDraft"
                :disabled="isSaving"
                :label="$tk('CreateOrUpdateUserDialog.StandardDraft')"
              />
            </div>

          </div>

        </PDialogContent>
        <PDialogActions>
          <div
            class="flex w-full"
            :class="{
              'justify-between': userId,
              'justify-end': !userId,
            }"
          >
            <PButtonGroup>
              <PButton
                v-if="userId"
                class="self-start"
                color="secondary"
                icon="envelope"
                :disabled="isSaving"
                @click="onPassword"
              >
                <PTranslation k="CreateOrUpdateUserDialog.ResetPassword" />
              </PButton>
              <PButton
                v-if="userId && loginUser.isGlobalAdmin"
                class="self-start"
                color="secondary"
                :disabled="isSaving"
                @click="onChangePassword"
              >
                <PTranslation k="CreateOrUpdateUserDialog.NewPasswordSet" />
              </PButton>
            </PButtonGroup>

            <PButtonGroup>
              <PButton
                color="secondary"
                :disabled="isSaving"
                @click="$emit('close')"
              >
                <PTranslation k="Common.Actions.Cancel" />
              </PButton>

              <PButton
                :disabled="!canSubmit"
                :loading="isSaving"
                type="submit"
              >
                <PTranslation k="Common.Actions.Save" />
              </PButton>
            </PButtonGroup>
          </div>
        </PDialogActions>
      </form>
    </PDialog>
  </portal>
</template>

<script>
import PUserLocations from "../../components/PUserLocations.vue";

import http from "@/http";
import api from "../../api";
import { mapGetters } from "vuex";
import { filter, find, findIndex, forEach, get, map, pick, some } from "lodash";

export default {
  name: "UserDialog",

  components: {
    PUserLocations,
  },

  props: {
    userId: {
      type: String,
      default: "",
    },
    customerId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      user: {
        id: "",
        name: "",
        phone: "",
        email: "",
        locationId: "",
        isCustomerAdmin: false,
        isSuperUser: false,
        isSuperUserLocation: false,
        reportLevel: 0,
        userStatus: 1,
        language: "",
        loginName: "",
        mailNewCust: false,
        mailDisagree: false,
        mailDeviation: false,
        mailStock: false,
        mailAutoCommitTO: false,
        mailHighVolume: false,
        confirmedGDPR: false,
      },
      userLocations: [],
      userLocationIds: [],
      locations: [],
      webTasks: [],
      menus: [],
      reports: [],
      languages: [],
      isLoading: false,
      isSaving: false,
      changePasswordVisible: false,
      newPassword: "",
      confirmNewPassword: ""
    };
  },
  computed: {
    ...mapGetters(["customer", "location", "loginUser"]),
    canSubmit() {
      return (
        this.user.name !== "" &&
        this.user.phone !== "" &&
        this.user.email !== "" &&
        this.user.locationId !== "" &&
        this.user.language !== ""
      );
    },
    dialogTitle() {
      return !this.isLoading && this.userId
        ? `${this.user.name} - ${this.user.phone}`
        : !this.isLoading
        ? this.$tk("CreateOrUpdateUserDialog.NewUser")
        : "...";
    },
    userActive: {
      get: function() {
        return this.user.userStatus === "Active" ? true : false;
      },
      set: function(value) {
        this.user.userStatus = value ? "Active" : "Inactive";
      },
    },
    selectedLocation() {
      return find(this.locations, (l) => l.id === get(this.user, "locationId", ""));
    },
    disableFinancialReportsCheckbox() {
      return this.isSaving && !this.user.isCustomerAdmin && !this.user.isGlobalAdmin
    }
  },

  methods: {
    async onPassword() {
      try {
        await http.post("/UserPasswordRequest", {
          userLoginName: this.user.loginName,
          url: `${location.protocol}//${location.host}/reset-password`,
        });
        this.$store.dispatch("notify", {
          type: "positive",
          text: this.$tk("CreateOrUpdateUserDialog.PasswordRequestMessage", true),
        });
      } catch (error) {
        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason,
        });
      }
    },

    onChangePassword() {
      this.changePasswordVisible = !this.changePasswordVisible;
    },

    async onSubmit() {
      this.isSaving = true;

      // USER
      try {
        let args = pick(this.user, [
          "allowFinancialReports",
          "email",
          "isCustomerAdmin",
          "isDefaultDraft",
          "isSuperUser",
          "isSuperUserLocation",
          "language",
          "locationId",
          "loginName",
          "mailAutoCommitTO",
          "mailDeviation",
          "mailDisagree",
          "mailHighVolume",
          "mailNewCust",
          "mailStock",
          "name",
          "phone",
          "reportLevel",
          "userStatus",
        ]);

        if (this.user.id) {
          args.id = this.user.id;
        } else {
          // upon creation an url is required for registration confirmation email
          args.url = `${location.protocol}//${location.host}/reset-password?new=1`;
        }

        this.user = await api.createOrUpdateUser(args);
        if (this.user.id === this.loginUser.id) this.$store.commit('setStatusUser', this.user)

        // WEBTASKS
        forEach(this.webTasks, (webTask) => {
          if (!webTask.visible) {
            webTask.checked = false;
          }
        });
        await api.userTasksUpdate(this.user.id, this.user.locationId, this.webTasks);

        // USERLOCATIONS
        const deletedLocations = filter(
          this.userLocations,
          (l) => l.canDelete && this.userLocationIds.indexOf(l.locationId) === -1
        );
        const deleteLocationPromises = map(deletedLocations, (l) =>
          http.delete("UserLocation", {
            params: { userId: this.user.id, locationId: l.locationId },
          })
        );

        const mainLocationInSecondary = find(
          this.userLocations,
          (l) => l.canDelete && l.locationId === this.user.locationId
        );
        if (mainLocationInSecondary) {
          deleteLocationPromises.push(
            http.delete("UserLocation", {
              params: { userId: this.user.id, locationId: mainLocationInSecondary.locationId },
            })
          );
        }

        const addedLocationIds = filter(
          this.userLocationIds,
          (id) => findIndex(this.userLocations, (l) => l.locationId === id) === -1
        );
        const addedLocationPromises = map(addedLocationIds, (id) =>
          http.post("UserLocation", {
            userId: this.user.id,
            locationId: id,
          })
        );

        const userLocationPromises = deleteLocationPromises.concat(addedLocationPromises);
        if (userLocationPromises.length > 0) {
          await Promise.all(deleteLocationPromises);
        }

        this.$emit("updated"); // Notifies parent to update its data
        this.$emit("close");
      } catch (e) {
        this.$notifyError(
          this.$tk("CreateOrUpdateUserDialog.ErrorSavingUser", true),
          this.$formatApiError(e)
        );
      }

      this.isSaving = false;
    },

    onCancelChangePassword() {
      this.changePasswordVisible = false;
      this.newPassword = "";
      this.confirmNewPassword = "";
    },

    async onSubmitPassword() {
      this.isSaving = true;
      try {
        await http.post("/UserPassword", {
          userLoginName: this.user.loginName,
          newPassword: this.newPassword,
        });
        this.$store.dispatch("notify", {
          type: "positive",
          text: this.$tk("CreateOrUpdateUserDialog.PasswordSetMessage", true),
        });
      } catch (error) {
        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason,
        });
      }
      this.isSaving = false;

      // close and reset password "dialog"
      this.changePasswordVisible = false;
      this.newPassword = "";
      this.confirmNewPassword = "";
    },

    updateWebTaskVisibility() {
      if (this.selectedLocation) {
        forEach(this.webTasks, (webTask) => {
          let visible = true;
          if (webTask.id === "CONTAINER" && !this.selectedLocation.isContainerActive) {
            visible = false;
          } else if (
            webTask.id === "SHIPMENT" &&
            !(this.customer.isPooling || this.customer.isCommission)
          ) {
            visible = false;
          } else if (webTask.id === "DISPATCH" && this.selectedLocation.isSmartRetur) {
            visible = false;
          } else if (
            webTask.id === "EXPEDITION" &&
            !this.selectedLocation.isSmartRetur &&
            !this.selectedLocation.isDispatch
          ) {
            visible = false;
          }
          webTask.visible = visible;
        });
      }
    },
  },

  async created() {
    this.isLoading = true;

    const [locations, languages, webTasks] = await Promise.all([
      api.locationsGet(this.customerId),
      api.languagesGet(),
      api.webTasksGet(),
    ]);

    this.locations = locations;
    this.languages = languages;

    forEach(webTasks, (wt) => {
      wt.visible = false;
      wt.checked = true;
      wt.initial = false;
    });

    if (this.userId) {
      const [user, webTaskUsers, userLocations] = await Promise.all([
        api.userGet(this.userId),
        api.webTaskUsersGet(this.userId),
        api.userLocationsGet(this.userId),
      ]);

      forEach(webTasks, (wt) => {
        wt.checked = some(webTaskUsers, (wtu) => wtu.taskId === wt.id);
        wt.initial = wt.checked;
      });

      this.user = user;
      this.userLocations = userLocations;
      this.userLocationIds = map(
        filter(userLocations, (l) => l.locationId !== this.user.locationId),
        (l) => l.locationId
      );
    } else {
      // default to first location
      if (locations.length > 0) {
        this.user.locationId = locations[0].id;
      }

      // default to first language
      if (languages.length > 0) {
        this.user.language = languages[0].name;
      }
    }

    this.reports = [
      {
        value: 0,
        label: this.$tk("Common.ReportLevel.Location"),
      },
      {
        value: 1,
        label: this.$tk("Common.ReportLevel.Customer"),
      }
    ];

    if (this.customer.chainId) {
      this.reports.push({
        value: 2,
        label: this.$tk("Common.ReportLevel.Chain"),
      });
    }

    this.webTasks = webTasks;

    this.updateWebTaskVisibility();

    this.isLoading = false;
  },
};
</script>
